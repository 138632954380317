import React, { useState, useEffect, useContext } from "react";
import classes from "./Home.module.css";
// Firebase
import app from "../../config/firebase";
// Routing
import { withRouter } from "react-router";
// Components
import HomeToolbar from "../../components/Travaux/Navigation/HomeToolbar/HomeToolbar";
import HomeFooter from "../../components/Travaux/Footer/HomeFooter";
import ViewSelector from "../../components/HomePage/ViewSelector/ViewSelector";
import Calendar from "../../components/HomePage/Calendar/Calendar";
import Projet from "../../components/HomePage/Projet/Projet";
// context
import { AuthContext } from "../../contexts/AuthContext";

const Home = ({ history }) => {
  const [view, setView] = useState(1);
  const [claims, setClaims] = useState();

  const [width, setWidth] = useState(window.innerWidth);
  const { currentUser } = useContext(AuthContext);
  useEffect(() => {
    // Get custom claims
    currentUser &&
      app
        .auth()
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => setClaims(idTokenResult.claims))
        .catch((err) => console.log(err));

    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <div className={classes.Home}>
      <HomeToolbar />
      <div className={classes.SubNavbar}>
        <ViewSelector width={width} view={view} setView={setView} />
        {/* <div className={classes.ActionButton}>
          <div className={classes.ActionButtonSvg}>
            <img
              style={{ width: "100%" }}
              alt="hand"
              src={require(`../../assets/homePage/main.svg`)}
            />
          </div>
          <p onClick={() => history.push("/travaux")}>
            Donner votre avis {">"}
          </p>
        </div> */}
      </div>
      <div className={classes.main}>
        {view === 1 ? (
          <Projet
            width={width}
            claims={claims}
            clicked={() => history.push("/travaux")}
          />
        ) : (
          <Calendar width={width} />
        )}
      </div>
      <div className={classes.Footer}>
        <HomeFooter />
      </div>
      <div className={classes.Frise}>
        <img
          style={{ width: "100%" }}
          alt="Photo"
          src={require(`../../assets/homePage/frise.png`)}
        />
      </div>
    </div>
  );
};

export default withRouter(Home);
